import React from "react";
import * as SH from "@shared";
import { Row, Container, Col } from "react-bootstrap";
import * as S from "./Error404.styles";
import ReactHtmlParser from "react-html-parser";
import { useFetchDefaultHeroImg } from "@hooks";

export const HeroSection = ({
  bgHero = {},
  image,
  title,
}: THeroSection404Props) => {
  const { data } = useFetchDefaultHeroImg();
  const { defaultHero } = data.strapiGlobal;
  const fullHeroImg = { ...defaultHero, ...bgHero };

  return (
    <S.HeroSection
      bgMobile={fullHeroImg.bgMobile.localFile.publicUrl}
      bgDesktop={fullHeroImg.bgDesktop.localFile.publicUrl}
      className="d-flex flex-column justify-content-center align-items-center pt-5 pt-lg-0"
    >
      <S.Header>
        40<span>4</span>
      </S.Header>
      <SH.Text.TextBody>{title}</SH.Text.TextBody>
      {image && (
        <SH.Image src={image.localFile.publicUrl} heightImg={height}></SH.Image>
      )}
    </S.HeroSection>
  );
};

const height = {
  desktop: 456.58,
  mobile: 276.58,
};

export type THeroSection404Props = {
  title: string;
  bgHero?: {};
  image: {
    localFile: {
      publicUrl: string;
    };
  };
};
