import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import { HeroSection } from "@components/404Page";

import { TPage404 } from "@types";

const NotFoundPage = ({ data }: TPage404) => {
  const { hero, image, title } = data.strapiPage404;

  return (
    <Layout seo={seo}>
      <HeroSection
        image={image}
        bgHero={{ bgMobile: hero.bgMobile, bgDesktop: hero.bgDesktop }}
        title={title}
      />
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  {
    strapiPage404 {
      image {
        localFile {
          publicUrl
        }
      }
      title
      hero {
        bgMobile {
          localFile {
            publicUrl
          }
        }
        bgDesktop {
          localFile {
            publicUrl
          }
        }
      }
    }
  }
`;
const seo = {
  metaTitle: "Page not found",
  metaDescription: "Page not found 404",
};
