import styled from "styled-components";
import * as SH from "@shared";
import * as theme from "@theme";

export const HeroSection = styled(SH.Wrapper.HeroSection)`
  min-height: 75vh;
  width: 100%;
  background-size: cover;
`;

export const Header = styled.h1`
  font-size: 100px;
  line-height: 122px;
  font-weight: 900;
  color: ${theme.colors.darkblue};
  span {
    transform: rotate(90deg);
    display: inline-block;
    padding: 20px;
    padding-left: 10px;
  }
`;
